<template>
  <div class="mb-10">
    <v-form ref="dataImportForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12" md="12">
          <v-label>Please select how the company employees will be imported into the portal</v-label>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="dataImportProcess"
            :items="dataImportOptions"
            single-line
            outlined
            dense
            hide-details
            class="m-0"
            :rules="[validators.required]"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import { mdiMinusCircle } from '@mdi/js';
import { required } from '@core/utils/validation';
import { DATA_IMPORT_MANUAL } from '@/constants';

export default {
  components: {},
  props: ['companyData'],
  setup() {
    const dataImportForm = ref(null);
    const isFormValid = ref(false);
    const attributes = ref([]);
    const dataImportProcess = ref(DATA_IMPORT_MANUAL);
    const dataImportOptions = [
      {
        text: 'Manual',
        value: DATA_IMPORT_MANUAL,
      },
    ];

    const fieldMappings = ref([]);
    const addFieldMapping = () => {
      fieldMappings.value.push({
        id: fieldMappings.value.length,
        deguraField: '',
        mapToField: '',
      });
    };
    const removeFieldMapping = (fieldMapping) => {
      fieldMappings.value.splice(
        fieldMappings.value.findIndex((item) => item.id === fieldMapping.id),
        1,
      );
    };
    const textUpperCase = 'text-uppercase';
    const fieldMappingHeaders = computed(() => {
      return [
        {
          text: 'Degura field',
          value: 'deguraField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Map to company field',
          value: 'mapToField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const valueMappings = ref([]);
    const addValueMapping = () => {
      valueMappings.value.push({
        id: valueMappings.value.length,
        deguraFieldValue: '',
        mapToField: '',
        mapToFieldValue: '',
      });
    };
    const removeValueMapping = (valueMapping) => {
      valueMappings.value.splice(
        valueMappings.value.findIndex((item) => item.id === valueMapping.id),
        1,
      );
    };
    const valueMappingHeaders = computed(() => {
      return [
        {
          text: 'Degura field-value(s)',
          value: 'deguraFieldValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Company field',
          value: 'mapToField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Map to company field-value(s)',
          value: 'mapToFieldValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const filters = ref([]);
    const addFilter = () => {
      filters.value.push({
        id: filters.value.length,
        companyField: '',
        logicOperator: '',
        filterValue: '',
      });
    };
    const removeFilter = (filter) => {
      filters.value.splice(
        filters.value.findIndex((item) => item.id === filter.id),
        1,
      );
    };
    const resetFilters = () => {
      filters.value = [];
    };
    const filterHeaders = computed(() => {
      return [
        {
          text: 'Filter of company',
          value: 'companyField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Logic',
          value: 'logicOperator',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Value',
          value: 'filterValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const logicOperatorOptions = [
      {
        text: '=',
        value: 'ne',
      },
      {
        text: '!=',
        value: 'eq',
      },
    ];

    return {
      isFormValid,
      dataImportForm,
      onSync,

      dryRun,
      isDryRunning,
      dryRunResults,
      isSyncRunning,

      dataImportOptions,
      dataImportProcess,

      fieldMappings,
      addFieldMapping,
      removeFieldMapping,
      fieldMappingHeaders,

      valueMappings,
      addValueMapping,
      removeValueMapping,
      valueMappingHeaders,

      filters,
      addFilter,
      removeFilter,
      resetFilters,
      filterHeaders,

      DATA_IMPORT_MANUAL,
      logicOperatorOptions,
      attributes,

      icons: {
        mdiMinusCircle,
      },

      validators: {
        required,
      },
    };
  },
};
</script>
