import axios from '@/plugins/axios';

export const getCompanyConfig = (companyId) => {
  return companyId ? axios.get(`/onboarding/company/${companyId}`) : null;
};

export const updateCompanyConfig = (id, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`/onboarding/company/${id}`, payload, config);
};
