<template>
  <v-stepper v-model="activeStep" flat class="transparent custom-header" alt-labels height="auto">
    <p v-if="activeStep === 1" class="text-center">
      Here you can set up 2FA, company logo, employer contribution and the welcome video. <br />Please note that if a
      welcome video is not set, the default video will be shown.
    </p>
    <p v-if="activeStep === 2" class="text-center">
      Here you can assign and manage the Consultant, Account Manager, and HR Manager roles. Please note that there will
      be a row for each role added per person. For example, user X will appear two times if they are assigned two roles.
      <v-alert
        v-model="isSalesforceAlertVisible"
        color="info text-center"
        border="left"
        dark
        dismissible
        class="mt-6"
        type="info"
      >
        Please verify that the company portal ID is set in Salesforce.
      </v-alert>
    </p>

    <p v-if="activeStep === 3" class="text-center">
      To show the insurance provider, please select it by checking the checkbox.
    </p>
    <p v-if="activeStep === 4" class="text-center">
      <v-alert v-model="isCalculationsAlertVisible" color="info text-center" border="left" dark dismissible type="info">
        Please add at least one example calculation before continuing.
      </v-alert>
    </p>
    <p v-if="activeStep === 5" class="text-center">
      Please select how the company employees will be imported into the portal.
    </p>
    <!-- Header -->
    <v-stepper-header :disabled="loadings.loading1">
      <!-- Header: Step 1 -->
      <v-stepper-step :complete="activeStep > 1" step="1" @click="activeStep = 1">
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text-sm font-weight-semibold text--primary">General</span>
          <span class="text-xs text--secondary">Settings</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step :complete="activeStep > 2" step="2" @click="activeStep = 2">
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text-sm font-weight-semibold text--primary">Roles</span>
          <span class="text-xs text--secondary">Permissions</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>
      <!-- Header: Step 3 -->
      <v-stepper-step :complete="activeStep > 3" step="3" @click="activeStep = 3">
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text-sm font-weight-semibold text--primary">Insurance</span>
          <span class="text-xs text--secondary">Set up </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 4 -->
      <v-stepper-step :complete="activeStep > 4" step="4" @click="activeStep = 4">
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text-sm font-weight-semibold text--primary">Calculations</span>
          <span class="text-xs text--secondary">Set up </span>
        </div>
      </v-stepper-step>
      <v-divider></v-divider>
      <!-- Header: Step 5 -->
      <v-stepper-step :complete="activeStep > 4" step="5" @click="activeStep = 5">
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text-sm font-weight-semibold text--primary">Data</span>
          <span class="text-xs text--secondary">Import process </span>
        </div>
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Items -->
    <v-card>
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <GeneralSettings :company-data="companyData" :company-config="companyConfig" @next="activeStep = 2" />
        </v-stepper-content>
        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2">
          <RolesPermissions
            :company-data="companyData"
            :org-companies="orgCompanies"
            @stepBack="activeStep = 1"
            @next="activeStep = 3"
          />
        </v-stepper-content>

        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3">
          <Insurance @stepBack="activeStep = 2" @next="activeStep = 4" />
        </v-stepper-content>
        <!-- Stepper Content: Step 4 -->
        <v-stepper-content step="4">
          <Calculations
            v-if="companyConfig"
            :company-config="companyConfig"
            :organization="companyData.organization"
            @stepBack="activeStep = 3"
            @next="activeStep = 5"
          />
        </v-stepper-content>
        <!-- Stepper Content: Step 4 -->
        <v-stepper-content step="5">
          <Data :company-data="companyData" />
          <div class="d-flex justify-space-between">
            <v-btn outlined :disabled="loadings.loading1" @click="activeStep = 4"> Back </v-btn>
            <v-btn
              color="primary"
              :loading="loadings.loading1"
              :disabled="loadings.loading1 || !stepperConfirmCheckBox"
            >
              Done
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-card>
  </v-stepper>
</template>

<script>
import { onMounted, ref, getCurrentInstance, watch } from '@vue/composition-api';
import { mdiCached, mdiCheckBold, mdiAlert } from '@mdi/js';
import { getCompanyConfig } from '../../api/onboarding';
import { getCompaniesByOrganizationId } from '../../api/organizations';
// import { getCompanyConfig } from "../../api/companies";

import appConfig from '../../../appConfig';

import GeneralSettings from './step-1/GeneralSettings.vue';
import RolesPermissions from './step-2/RolesPermissions.vue';
import Insurance from './step-3/Insurance.vue';
import Calculations from './step-4/Calculations.vue';
import Data from './step-5/Data.vue';

export default {
  components: {
    GeneralSettings,
    RolesPermissions,
    Insurance,
    Calculations,
    Data,
  },
  props: ['companyData', 'orgCompanies'],
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const companyConfig = ref(null);
    const stepperConfirmCheckBox = ref(false);
    const activeStep = ref(1);
    const isSalesforceAlertVisible = ref(true);
    const isCalculationsAlertVisible = ref(true);
    const loadings = ref({
      loading1: false,
    });
    const triggerLoading = (loadingIndex) => {
      loadings.value[loadingIndex] = true;
    };
    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
    });

    const setStepValidationStatus = (step, v) => {
      activeStep.value = step + 1;
      stepValidationResult.value[step] = v;
      stepValidationResult.value[step + 1] = v;
      if (step > 1) {
        stepValidationResult.value[step - 1] = v;
      }
    };

    const resetOnSteps = (step) => {
      stepValidationResult.value[step] = false;
    };

    const createLogoSrcUrl = async (path) => {
      return `${path}`;
    };

    const fetchCompanyConfig = () => {
      getCompanyConfig(vm.$route.params.id).then(async (res) => {
        if (res.data.contribution.employerSubsidy && res.data.contribution.employerSubsidy < 1) {
          res.data.contribution.employerSubsidy = res.data.contribution.employerSubsidy * 100;
        }
        res.data.logoSrc = await createLogoSrcUrl(res.data.logo);
        companyConfig.value = res.data;
      });
    };

    watch(activeStep, (n, o) => {
      if (n < o && o !== 4) {
        vm.$nextTick(() => resetOnSteps(o));
      }
    });

    fetchCompanyConfig();

    return {
      loadings,
      triggerLoading,
      stepValidationResult,
      setStepValidationStatus,
      activeStep,
      isSalesforceAlertVisible,
      isCalculationsAlertVisible,
      companyConfig,

      stepperConfirmCheckBox,

      // icon
      icons: { mdiCached, mdiCheckBold, mdiAlert },
    };
  },
};
</script>
<style lang="scss">
.v-stepper__header {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.v-stepper__wrapper {
  padding: 10px;
}
.v-stepper__step {
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}
.cursor-disabled {
  cursor: not-allowed;
}
// needed because stepper already has special style
.v-divider {
  &--stepper {
    border-width: thin 0 0 0 !important;
    &:after {
      display: none;
    }
  }
}
</style>
