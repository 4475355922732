<template>
  <div class="py-sm-10 py5">
    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <v-card v-if="companyData" class="v-card--no-border v-card--no-bg mb-5">
          <h1 class="font-weight-bold mb-2 text-center">{{ companyData.companyName }}</h1>
          <p class="text-center mb-5 font-primary text-xs">
            Organisation:
            <span class="font-weight-bold">{{ companyData.organization.organizationName }}</span>
            <br />
          </p>
          <p class="text-center">
            Status: <v-chip small color="primary">Onboarded</v-chip>
            <br />
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <OnboardCompanyStepper :company-data="companyData" :org-companies="orgCompanies" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
} from '@mdi/js';
import { ref, getCurrentInstance, onMounted, computed, watch } from '@vue/composition-api';
import OnboardCompanyStepper from '@/components/onboard-company/Stepper.vue';
import { getCompanyById } from '../../api/companies';
import { getCompaniesByOrganizationId } from '../../api/organizations';

export default {
  name: 'GlobalAdminOnboard',
  components: { OnboardCompanyStepper },
  setup() {
    const vm = getCurrentInstance().proxy;
    const companyData = ref(null);
    const orgCompanies = ref(null);

    const fetchCompanyData = () => {
      window.document.title = 'Degura';
      getCompanyById(vm.$route.params.id).then((res) => {
        companyData.value = res.data;
        window.document.title = res.data.companyName;
        getCompaniesByOrganizationId(0, 100, '', res.data.organization.id).then((res) => {
          orgCompanies.value = res.data.page.map((c) => {
            return { text: c.companyName, value: c.id };
          });
        });
      });
    };

    fetchCompanyData();

    return {
      // IntegrationOptions,
      // filterByIntegration,
      companyData,
      orgCompanies,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
      },
    };
  },
};
</script>

<style scoped></style>
