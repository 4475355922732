import axios from '@/plugins/axios';

const buildQuery = (offset, limit, search) => {
  let query = '';

  if (offset) {
    query += `&offset=${offset}`;
  }

  if (limit) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&searchString=${search}`;
  }

  return query;
};

export const getOrganizationsList = (offset = 0, limit = 15, search) => {
  const q = buildQuery(offset, limit, search);
  return axios.get(`/organizations?${q}`);
};

export const getCompaniesByOrganizationId = (offset = 0, limit = 15, search, id) => {
  const q = buildQuery(offset, limit, search);
  return axios.get(`/organizations/${id}/companies?${q}`);
};

export const getOrganizationById = (id) => axios.get(`/organizations/${id}`);

export const getOrgExampleCalculationTemplates = (id) => axios.get(`/exampleCalculations/${id}/organization`);
