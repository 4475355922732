<template>
  <v-form
    v-if="calculation"
    id="exampleCalculationsForm"
    ref="exampleCalculationsForm"
    v-model="isFormValid"
    class="multi-col-validation"
    @submit.prevent="onSubmit"
  >
    <example-calculations-select v-if="!isEdit" :options="exampleCalculationsTemplate" @change="calculation = $event" />
    <div v-if="!orgTemplates">
      <v-row justify="center">
        <v-col cols="8">
          <h3>General information</h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.name"
                label="Name"
                outlined
                dense
                placeholder="Name"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="menuref"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    id="dob"
                    v-model="calculation.dateOfBirth"
                    label="Date of Birth"
                    readonly
                    outlined
                    dense
                    :prepend-inner-icon="icons.mdiCalendar"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  ref="picker"
                  v-model="calculation.dateOfBirth"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  color="primary"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="calculation.taxClass"
                :items="taxClassItems"
                label="Tax Class"
                dense
                outlined
                item-text="name"
                item-value="class"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="calculation.churchTax"
                :items="churchTaxEnabled"
                label="Church Tax"
                dense
                outlined
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.childAllowance"
                lang="de-DE"
                type="number"
                label="Child Allowance"
                outlined
                dense
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.grossWage"
                lang="de-DE"
                type="number"
                label="Gross Wage"
                outlined
                dense
                placeholder="Gross Wage"
                :rules="
                  isEdit
                    ? [validators.integerWithDecimalValidator]
                    : [validators.integerWithDecimalValidator, validators.required]
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.netContribution"
                lang="de-DE"
                type="number"
                label="Net Contribution"
                outlined
                dense
                placeholder="Net Contribution"
                :rules="
                  isEdit
                    ? [validators.integerWithDecimalValidator]
                    : [validators.integerWithDecimalValidator, validators.required]
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8"> <v-divider class="mt-3"></v-divider> </v-col
      ></v-row>
      <v-row justify="center">
        <v-col cols="8">
          <h3>Company Pension</h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8" class="pt-0">
          <v-row>
            <v-col cols="12" md="6">
              <p class="mb-5">No company pension</p>

              <v-text-field
                id="net-wage"
                v-model="calculation.noCompanyPension.netWage"
                lang="de-DE"
                type="number"
                label="Net Wage"
                outlined
                dense
                placeholder="Net Wage"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <p class="mb-5">With company pension</p>

              <v-text-field
                v-model="calculation.withCompanyPension.netWage"
                lang="de-DE"
                type="number"
                label="Net Wage"
                outlined
                dense
                placeholder="Net Wage"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.noCompanyPension.socialSecurityDues"
                lang="de-DE"
                type="number"
                label="Social Security Dues"
                outlined
                dense
                placeholder="Social Security Dues"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.socialSecurityDues"
                lang="de-DE"
                type="number"
                label="Social Security Dues"
                outlined
                dense
                placeholder="Social Security Dues"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.noCompanyPension.taxDues"
                lang="de-DE"
                type="number"
                label="Taxes Due"
                outlined
                dense
                placeholder="Taxes Due"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.taxDues"
                lang="de-DE"
                type="number"
                label="Taxes Due"
                outlined
                dense
                placeholder="Taxes Due"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8"> <v-divider class="mt-3"></v-divider> </v-col
      ></v-row>
      <v-row justify="center">
        <v-col cols="8">
          <h3>Payments</h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.payments.paymentEmployeeContribution"
                lang="de-DE"
                type="number"
                label="Employee Contribution*"
                outlined
                dense
                placeholder="Employee Contribution*"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.payments.paymentEmployerContribution"
                lang="de-DE"
                type="number"
                label="Employer Contribution*"
                outlined
                dense
                placeholder="Employer Contribution*"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.payments.savingsInDues"
                lang="de-DE"
                type="number"
                label="Savings In Dues"
                outlined
                dense
                placeholder="Savings In Dues"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.payments.paymentEmployerSubsidy"
                lang="de-DE"
                type="number"
                label="Employer's subsidy"
                outlined
                dense
                min="0"
                max="1"
                placeholder="Employer's subsidy"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="calculation.withCompanyPension.calculationMethod"
                :items="[
                  { name: EXAMPLE_CALCULATION_METHODS.TOTAL, value: EXAMPLE_CALCULATION_METHODS.TOTAL },
                  { name: EXAMPLE_CALCULATION_METHODS.DEFERRED, value: EXAMPLE_CALCULATION_METHODS.DEFERRED },
                ]"
                label="Calculation method"
                dense
                outlined
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8"> <v-divider class="mt-3"></v-divider> </v-col
      ></v-row>
      <v-row justify="center">
        <v-col cols="8">
          <h3>Contract Lifetime</h3>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.duration"
                lang="de-DE"
                type="number"
                label="Duration"
                outlined
                dense
                placeholder="Duration"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.totalPaidInOwn"
                lang="de-DE"
                type="number"
                label="Total Paid In Own"
                outlined
                dense
                placeholder="Total Paid In Own"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.sharePaidInOwn"
                lang="de-DE"
                type="number"
                label="Share Paid In Own"
                outlined
                dense
                placeholder="Share Paid In Own"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.totalPaidInOther"
                lang="de-DE"
                type="number"
                label="Total Paid In Other"
                outlined
                dense
                placeholder="Total Paid In Other"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.sharePaidInOther"
                lang="de-DE"
                type="number"
                label="Share Paid In Other"
                outlined
                dense
                placeholder="Share Paid In Other"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.capitalPaymentGuaranteed"
                lang="de-DE"
                type="number"
                label="Capital Payment Guaranteed"
                outlined
                dense
                placeholder="Capital Payment Guaranteed"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.projectedAnnualReturn"
                lang="de-DE"
                type="number"
                label="Projected Annual Return"
                outlined
                dense
                placeholder="Projected Annual Return"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="calculation.withCompanyPension.contractLifetime.capitalPaymentProjected"
                lang="de-DE"
                type="number"
                label="Capital Payment Projected"
                outlined
                dense
                placeholder="Capital Payment Projected"
                :rules="[validators.integerWithDecimalValidator]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8"> <v-divider class="mt-3"></v-divider> </v-col
      ></v-row>
      <v-row justify="center">
        <v-col cols="8">
          <h3>Files</h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                id="file-de"
                v-model="uploadedFileDE"
                label="Tariff sheet (DE)"
                outlined
                dense
                :rules="[validators.fileRequired]"
                @change="handleFileUploadDE"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                id="file-en"
                v-model="uploadedFileEN"
                label="Tariff sheet (EN)"
                outlined
                dense
                :rules="[validators.fileRequired]"
                @change="handleFileUploadEN"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api';
import {
  required,
  numberRequired,
  integerValidator,
  integerWithDecimalValidator,
  fileRequired,
} from '@core/utils/validation';

import { mdiCalendar, mdiAt, mdiCellphone, mdiEmailOutline } from '@mdi/js';
import { createExampleCalculation, editCompanyExampleCalculation, useOrgTemplateForCompany } from '@/api/companies/';
import ExampleCalculationsSelect from '@/components/example-calculations/ExampleCalculationsSelect.vue';
import { EXAMPLE_CALCULATION_METHODS, EXAMPLE_CALCULATION_TEMPLATES } from '@/constants';

export default {
  computed: {
    EXAMPLE_CALCULATION_METHODS() {
      return EXAMPLE_CALCULATION_METHODS;
    },
  },
  components: { ExampleCalculationsSelect },
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const date = ref(null);
    const menu = ref(false);
    const menuStartDate = ref(false);
    const menuStartRef = ref(null);
    const picker = ref(null);
    const menuref = ref(null);
    const allowSave = ref(false);

    const isFormValid = ref(false);
    const exampleCalculationsForm = ref(null);

    const taxClassItems = ref([
      { name: '1', class: 'TAX_CLASS_1' },
      { name: '2', class: 'TAX_CLASS_2' },
      { name: '3', class: 'TAX_CLASS_3' },
      { name: '4', class: 'TAX_CLASS_4' },
      { name: '5', class: 'TAX_CLASS_5' },
      { name: '6', class: 'TAX_CLASS_6' },
    ]);
    const churchTaxEnabled = ref([
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ]);

    const exampleCalculationsTemplate = ref(EXAMPLE_CALCULATION_TEMPLATES);

    const calculation = ref(null);
    onMounted(() => {
      if (props.orgTemplates !== null) {
        exampleCalculationsTemplate.value = props.orgTemplates;
      }

      if (props.excludeCalculations != null) {
        exampleCalculationsTemplate.value = exampleCalculationsTemplate.value.filter(
          (person) => props.excludeCalculations.indexOf(person.name) === -1,
        );
        calculation.value = exampleCalculationsTemplate.value[0];
      } else {
        calculation.value = exampleCalculationsTemplate.value[0];
      }

      if (props.isEdit) calculation.value = props.calculationToEdit;
    });
    watch(menu, (val) => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'));
    });
    watch(
      () => props.calculationToEdit,
      (val) => {
        if (props.isEdit) calculation.value = val;
      },
    );
    const uploadedFileDE = ref(null);
    const uploadedFileEN = ref(null);
    const handleFileUploadDE = (file) => {
      uploadedFileDE.value = file;
    };
    const handleFileUploadEN = (file) => {
      uploadedFileEN.value = file;
    };

    const save = (d) => {
      menuref.value.save(d);
    };

    const saveStartDate = (d) => {
      menuStartRef.value.save(d);
    };

    const saveChanges = () => {
      const isValid = exampleCalculationsForm.value.validate();
      if (!isValid) return;
    };
    const createCalculation = async () => {
      const isTemplateSelection = props.orgTemplates && props.orgTemplates.length;

      // TECH-1463 - BE needs to receive monthly value, we need to calculate it based on other fields

      const { payments } = calculation.value.withCompanyPension;
      const employeePayments = +(Number(payments.paymentEmployeeContribution) + Number(payments.savingsInDues));
      const employerSubsidyInCurrency = +(employeePayments * Number(payments.paymentEmployerSubsidy));
      const total = +(Number(payments.paymentEmployerContribution ?? 0) + employeePayments + employerSubsidyInCurrency);

      calculation.value = {
        ...calculation.value,
        withCompanyPension: {
          ...calculation.value.withCompanyPension,
          payments: {
            ...payments,
            monthlyTotal: String(total),
          },
        },
      };

      if (!isTemplateSelection) {
        const id = props.isTemplate && props.orgId ? props.orgId : props.companyConfig.id;

        await createExampleCalculation(
          id,
          uploadedFileDE.value,
          uploadedFileEN.value,
          calculation.value,
          props.isTemplate,
        )
          .then((res) => {
            vm.$emit('created');
          })
          .catch((error) => {
            vm.$store.commit('showMessage', {
              text: error,
              color: 'error',
              timeout: '10000',
            });
          });
      } else {
        await useOrgTemplateForCompany(props.companyConfig.id, calculation.value.id)
          .then((res) => {
            vm.$emit('selected');
          })
          .catch((error) => {
            vm.$store.commit('showMessage', {
              text: error,
              color: 'error',
              timeout: '10000',
            });
          });
      }
    };
    const editCalculation = async () => {
      await editCompanyExampleCalculation(
        calculation.value.id,
        uploadedFileDE.value,
        uploadedFileEN.value,
        calculation.value,
        !!calculation.value.inUse,
      )
        .then((res) => {
          calculation.value = null;
          vm.$emit('created');
        })
        .catch((error) => {
          vm.$store.commit('showMessage', {
            text: error,
            color: 'error',
            timeout: '10000',
          });
        });
    };
    const onSubmit = () => {
      const isValid = exampleCalculationsForm.value.validate();
      if (!isValid) return;
      if (!props.isEdit) createCalculation();
      else editCalculation();
    };

    return {
      date,
      menu,
      menuStartDate,
      picker,
      menuref,
      menuStartRef,
      save,
      saveStartDate,
      saveChanges,
      allowSave,
      isFormValid,
      onSubmit,
      exampleCalculationsForm,
      calculation,
      exampleCalculationsTemplate,
      taxClassItems,
      churchTaxEnabled,
      uploadedFileDE,
      uploadedFileEN,
      handleFileUploadDE,
      handleFileUploadEN,

      // icons
      icons: {
        mdiCalendar,
        mdiAt,
        mdiCellphone,
        mdiEmailOutline,
      },
      validators: {
        required,
        numberRequired,
        integerValidator,
        integerWithDecimalValidator,
        fileRequired,
      },
    };
  },
  props: {
    companyConfig: {
      default: null,
      type: Object,
    },
    calculationToEdit: {
      default: null,
      type: Object,
    },
    excludeCalculations: {
      default: null,
      type: Array,
    },
    isEdit: {
      default: false,
      type: Boolean,
    },
    isTemplate: {
      default: false,
      type: Boolean,
    },
    orgId: {
      default: null,
      type: String,
    },
    orgTemplates: {
      default: null,
      type: Array,
    },
  },
};
</script>
<style lang="scss">
.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px !important;
  }
}
</style>
