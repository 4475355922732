import axios from '@/plugins/axios';

export const createPlan = async (providerID, plan, benefitsDE, benefitsEN) => {
  let retId;
  const data = {
    name: plan,
    benefits: {
      de: benefitsDE,
      en: benefitsEN,
    },
  };
  await axios
    .post(`/plans/${providerID}`, data)
    .then((res) => (retId = res.data.id))
    .catch((err) => {
      throw new Error(err);
    });

  return retId;
};

export const addDocumentsForPlan = async (
  planID,
  tarifLabelDE,
  tarifLabelEN,
  tarifFileDE,
  tarifFileEN,
  factLabelDE,
  factLabelEN,
  factFileDE,
  factFileEN,
) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let retFactId;
  let retTariffId;
  if (factFileDE || factFileEN) {
    const tariffPayload = new FormData();
    const factPayload = new FormData();
    tariffPayload.append('fileDE', tarifFileDE);
    tariffPayload.append('fileEN', tarifFileEN);
    tariffPayload.append('type', 'TARIFF_SHEET');
    tariffPayload.append(
      'planDocument',
      new Blob(
        [
          JSON.stringify({
            de: {
              label: tarifLabelDE,
            },
            en: {
              label: tarifLabelEN,
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    factPayload.append('fileDE', factFileDE);
    factPayload.append('fileEN', factFileEN);
    factPayload.append('type', 'FACT_SHEET');
    factPayload.append(
      'planDocument',
      new Blob(
        [
          JSON.stringify({
            de: {
              label: factLabelDE,
            },
            en: {
              label: factLabelEN,
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    await axios
      .post(`/planDocuments/${planID}`, tariffPayload, config)
      .then((res) => (retTariffId = res.data.id))
      .catch((err) => {
        throw new Error(err);
      });
    await axios
      .post(`/planDocuments/${planID}`, factPayload, config)
      .then((res) => (retFactId = res.data.id))
      .catch((err) => {
        throw new Error(err);
      });

    return retTariffId, retFactId;
  }
  const payload = new FormData();
  payload.append('fileDE', tarifFileDE);
  payload.append('fileEN', tarifFileEN);
  payload.append('type', 'TARIFF_SHEET');
  payload.append(
    'planDocument',
    new Blob(
      [
        JSON.stringify({
          de: {
            label: tarifLabelDE,
          },
          en: {
            label: tarifLabelEN,
          },
        }),
      ],
      {
        type: 'application/json',
      },
    ),
  );

  await axios
    .post(`/planDocuments/${planID}`, payload, config)
    .then((res) => (retTariffId = res.data.id))
    .catch((err) => {
      throw new Error(err);
    });

  return retTariffId;
};

export const getPlanDocuments = (planId) => {
  return axios.get(`/planDocuments/${planId}`);
};
