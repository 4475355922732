<template>
  <v-form :id="formId" ref="planInfoForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="addPlan">
    <v-row class="px-3">
      <h4>Details</h4>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="providerData.selectedProvider.value"
          label="Provider"
          :items="providerData.providerList.value"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="plan"
          label="Plan name"
          :rules="[validators.required]"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <h4>Benefits</h4>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea v-model="benefitsDE" outlined label="Benefits (DE)" :rules="[validators.required]"></v-textarea>
        <!--<v-text-field-->
        <!--label="Benefits (DE)"-->
        <!--v-model="benefitsDE"-->
        <!--:rules="[validators.required]"-->
        <!--outlined-->
        <!--dense-->
        <!--hide-details-->
        <!--&gt;</v-text-field>-->
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea v-model="benefitsEN" outlined label="Benefits (EN)" :rules="[validators.required]"></v-textarea>
        <!--<v-text-field-->
        <!--label="Benefits (EN)"-->
        <!--v-model="benefitsEN"-->
        <!--:rules="[validators.required]"-->
        <!--outlined-->
        <!--dense-->
        <!--hide-details-->
        <!--&gt;</v-text-field>-->
      </v-col>
    </v-row>
    <v-row class="px-3">
      <h4>Tariff Sheets</h4>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="tarifLabelDE"
          label="Label (DE)"
          :rules="[validators.required]"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="tarifFileDE"
          label="Select and upload sheet (DE)"
          :rules="[validators.fileRequired]"
          hide-details
          class="py-0"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="tarifLabelEN"
          label="Label (EN)"
          :rules="[validators.required]"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="tarifFileEN"
          label="Select and upload sheet (EN)"
          :rules="[validators.fileRequired]"
          hide-details
          class="py-0"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <h4>Fact Sheets</h4>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="factLabelDE"
          label="Label (DE)"
          outlined
          dense
          hide-details
          :rules="[factFileDE ? validators.required : true]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="factFileDE"
          label="Select and upload sheet (DE)"
          hide-details
          class="py-0"
          :rules="[factLabelDE ? validators.fileRequired : true]"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="factLabelEN"
          label="Label (EN)"
          outlined
          dense
          hide-details
          :rules="[factFileEN ? validators.required : true]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="factFileEN"
          label="Select and upload sheet (EN)"
          hide-details
          class="py-0"
          :rules="[factLabelEN ? validators.fileRequired : true]"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';
import { required, fileRequired, numberRequired, integerValidator } from '@core/utils/validation';

import { mdiCalendar, mdiAt, mdiCellphone, mdiEmailOutline } from '@mdi/js';
import { createPlan, addDocumentsForPlan } from '@/api/plans';

export default {
  components: {},
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const isFormValid = ref(false);

    const planInfoForm = ref(null);
    const plan = ref('');
    const benefitsDE = ref('');
    const benefitsEN = ref('');
    const tarifLabelDE = ref('');
    const tarifLabelEN = ref('');
    const tarifFileDE = ref(null);
    const tarifFileEN = ref(null);
    const factLabelDE = ref('');
    const factLabelEN = ref('');
    const factFileDE = ref(null);
    const factFileEN = ref(null);

    const addPlan = () => {
      const isValid = planInfoForm.value.validate();
      if (!isValid) return;

      const splitBenefitsDE = benefitsDE.value.split('\n').filter((i) => !!i);
      const splitBenefitsEN = benefitsEN.value.split('\n').filter((i) => !!i);

      createPlan(props.providerData.selectedProvider.value, plan.value, splitBenefitsDE, splitBenefitsEN).then(
        (res) => {
          addDocumentsForPlan(
            res,
            tarifLabelDE.value,
            tarifLabelEN.value,
            tarifFileDE.value,
            tarifFileEN.value,
            factLabelDE.value,
            factLabelEN.value,
            factFileDE.value,
            factFileEN.value,
          ).then(() => {
            planInfoForm.value.reset();
            vm.$emit('close');
          });
        },
      );
    };

    return {
      isFormValid,
      plan,
      benefitsDE,
      benefitsEN,
      tarifLabelDE,
      tarifLabelEN,
      tarifFileEN,
      tarifFileDE,
      factLabelDE,
      factLabelEN,
      factFileEN,
      factFileDE,
      addPlan,
      planInfoForm,

      // icons
      icons: {
        mdiCalendar,
        mdiAt,
        mdiCellphone,
        mdiEmailOutline,
      },
      validators: {
        required,
        numberRequired,
        integerValidator,
        fileRequired,
      },
    };
  },
  props: {
    formId: {
      required: false,
    },
    providerData: {
      type: Object,
    },
  },
};
</script>
<style lang="scss">
.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px !important;
  }
}
</style>
