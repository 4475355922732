import axios from '@/plugins/axios';

export const getProviders = (offset, limit, search) => {
  let query = '';

  if (offset) {
    query += `&offset=${offset}`;
  }

  if (limit) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&nameProvider=${search}`;
  }

  return axios.get(`/providers/all?${query}`);
};

export const getProviderById = (id) => {
  return axios.get(`/providers/${id}`);
};

export const getProvidersInUse = (companyId) => {
  return axios.get(`/onboarding/${companyId}/plans`);
};

export const useProvider = (companyId, providers) => {
  return axios.put(`/onboarding/${companyId}/plans`, providers);
};

export const deleteProvider = (companyId, provider) => {
  return axios({
    method: 'DELETE',
    url: `/onboarding/${companyId}/plans`,
    data: provider,
  });
};

export const createProvider = async (logos, name) => {
  let retId;
  const payload = new FormData();
  payload.append('logo', logos);
  payload.append(
    'provider',
    new Blob([JSON.stringify({ name })], {
      type: 'application/json',
    }),
  );
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  await axios
    .post(`/providers`, payload, config)
    .then((res) => (retId = res.data.id))
    .catch((err) => {
      throw new Error(err);
    });

  return retId;
};
