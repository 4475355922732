<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-alert outlined text color="primary" class="mb-3 text-center" dense>
        <h4>Please choose a template:</h4>
        <v-select
          v-model="calculation"
          :items="options"
          item-text="name"
          dense
          hide-details
          outlined
          class="mt-2 pb-1"
          style="max-width: 250px; margin: auto"
          return-object
          @change="handleValue"
        ></v-select>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from '@vue/composition-api';

export default {
  props: ['options'],
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const calculation = ref(null);

    const handleValue = (v) => {
      vm.$emit('change', v);
    };

    onMounted(() => {
      if (props.options && props.options.length) calculation.value = props.options[0];
    });

    return {
      calculation,
      handleValue,
    };
  },
};
</script>

<style scoped lang="scss"></style>
