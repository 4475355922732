<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="12" md="12" class="mx-auto pt-0">
        <v-card flat class="mb-5">
          <v-card-text class="d-flex align-center flex-wrap px-0 py-0 border-top justify-end">
            <span class="pb-5 pr-0">
              <v-btn color="error" class="mr-3" :disabled="selectedCalculations.length === 0" @click="onDelete(false)">
                Delete
              </v-btn>
            </span>
            <v-spacer></v-spacer>
            <span class="pb-5 mr-2 pr-0">
              <v-btn
                color="secondary"
                :disabled="loadedExampleCalculations.length >= 3"
                @click="selectTemplateModal = true"
              >
                Select calculation template
              </v-btn>
            </span>
            <span class="pb-5 pr-0">
              <v-btn
                color="secondary"
                :disabled="loadedExampleCalculations.length >= 3"
                @click="createCalculationModal = true"
              >
                Create calculation
              </v-btn>
            </span>
          </v-card-text>

          <v-data-table
            v-model="selectedCalculations"
            :items="loadedExampleCalculations"
            :headers="headers"
            show-select
            class="text-no-wrap"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
              'items-per-page-text': $t('itemsPerPage'),
            }"
            @update:items-per-page="setLimit"
            @pagination="handlePagination"
          >
            <template #[`header.trending`]>
              <v-icon size="22">
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </template>

            <template #[`item.name`]="{ item }">
              <span class="text-no-wrap th">
                {{ item.name }}
              </span>
            </template>

            <template #[`item.inUse`]="{ item }">
              <span class="text-no-wrap th">
                {{ item.inUse ? 'Org template' : 'Custom' }}
              </span>
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{ item }">
              <div class="d-flex align-center justify-center">
                <!-- upload -->

                <!-- edit -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" @click="onEdit(item)" v-on="on">
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>

                <!-- delete -->

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" @click="onDelete(item)" v-on="on">
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('delete') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template slot="no-data">
              <div class="my-5">
                <p>{{ $t('myBavNoResults') }}</p>
              </div>
            </template>
          </v-data-table>
        </v-card>

        <ModalWindow
          :is-open="selectTemplateModal"
          title="Create calculation"
          :isFullScreen="true"
          :isFullScreenFixed="true"
        >
          <template #content>
            <ExampleCalculationsForm
              v-if="selectTemplateModal"
              class="mt-15 mb-10"
              formId="exampleCalculationsForm"
              :company-config="companyConfig"
              :excludeCalculations="calculationsToExclude"
              :org-templates="loadedTemplates"
              @selected="onSelectedTemplate"
            />
          </template>
          <template #actionsFullScreenClose>
            <v-btn icon dark @click="selectTemplateModal = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
          <template #actionsFullScreenSave>
            <v-btn dark text class="my-auto" type="submit" form="exampleCalculationsForm"> Select </v-btn>
          </template>
        </ModalWindow>

        <ModalWindow
          :is-open="createCalculationModal"
          title="Create calculation"
          :isFullScreen="true"
          :isFullScreenFixed="true"
        >
          <template #content>
            <ExampleCalculationsForm
              v-if="createCalculationModal"
              class="mt-15 mb-10"
              formId="exampleCalculationsForm"
              :company-config="companyConfig"
              :excludeCalculations="calculationsToExclude"
              @created="onCreatedCalculation"
            />
          </template>
          <template #actionsFullScreenClose>
            <v-btn icon dark @click="createCalculationModal = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
          <template #actionsFullScreenSave>
            <v-btn dark text class="my-auto" type="submit" form="exampleCalculationsForm"> Create </v-btn>
          </template>
        </ModalWindow>

        <ModalWindow
          :is-open="editCalculationModal"
          title="Edit calculation"
          :isFullScreen="true"
          :isFullScreenFixed="true"
        >
          <template #actionsFullScreenClose>
            <v-btn
              icon
              dark
              @click="
                editCalculationModal = false;
                calculationToEdit = null;
              "
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
          <template #actionsFullScreenSave>
            <v-btn dark text class="my-auto" type="submit" form="exampleCalculationsForm"> Save </v-btn>
          </template>
          <template #content>
            <ExampleCalculationsForm
              class="mt-15 mb-10"
              :isEdit="true"
              :calculationToEdit="calculationToEdit"
              @created="onEditedCalculation"
            />
          </template>
        </ModalWindow>

        <ModalWindow :is-open="deleteCalculationModal" error>
          <template #content>
            <p class="text-base">
              {{
                $t('deleteCalculationsConfirmation', {
                  count: selectedCalculations.length || '1',
                })
              }}
            </p>
          </template>
          <template #actions>
            <v-btn outlined color="primary" @click="deleteCalculationModal = false">
              {{ $t('buttonCancel') }}
            </v-btn>
            <v-btn color="primary" @click="confirmDelete">
              {{ $t('buttonConfirm') }}
            </v-btn>
          </template>
        </ModalWindow>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-5">
      <v-btn outlined @click="$emit('stepBack')"> Back </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :disabled="loadedExampleCalculations.length ? false : true"
        @click="$emit('next')"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed, onMounted } from '@vue/composition-api';
import {
  mdiCalendar,
  mdiEmailOutline,
  mdiCellphone,
  mdiClose,
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
} from '@mdi/js';
import { required, emailValidator, phoneNumberValidator } from '@core/utils/validation';
import ModalWindow from '@/components/modal/ModalWindow';
import {
  getCompanyExampleCalculations,
  deleteCompanyExampleCalculation,
  removeOrgTemplateForCompany,
} from '@/api/companies/';
import ExampleCalculationsForm from '@/components/example-calculations/ExampleCalculationsForm.vue';
import { getOrgExampleCalculationTemplates } from '@/api/organizations';

export default {
  components: { ModalWindow, ExampleCalculationsForm },
  props: ['companyConfig', 'organization'],
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const loading = ref(false);
    const loadedExampleCalculations = ref([]);
    const loadedTemplates = ref([]);
    const totalCalculations = ref(0);
    const menuref = ref(null);
    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const isFormValid = ref(false);
    const createCalculationModal = ref(false);
    const selectTemplateModal = ref(false);
    const editCalculationModal = ref(false);
    const deleteCalculationModal = ref(false);
    const selectedCalculations = ref([]);
    const calculationToDelete = ref(null);
    const calculationToEdit = ref(null);
    const calculationsToExclude = ref([]);
    const textUpperCase = 'text-uppercase';

    const headers = computed(() => {
      return [
        {
          text: 'Calculation',
          value: 'name',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Type',
          value: 'inUse',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const save = (d) => {
      menuref.value.save(d);
    };
    const onEditedCalculation = () => {
      calculationToEdit.value = null;
      editCalculationModal.value = false;
      getCompanyExampleCalculations('api', props.companyConfig.id)
        .then((res) => {
          loadedExampleCalculations.value = res.data;
        })
        .then(() => (loading.value = false));
      vm.$store.commit('showMessage', {
        text: 'Success: Edited calculation',
        color: 'success',
        timeout: '10000',
      });
    };

    const onCreatedCalculation = () => {
      createCalculationModal.value = false;
      getCompanyExampleCalculations('api', props.companyConfig.id)
        .then((res) => {
          loadedExampleCalculations.value = res.data;
        })
        .then(() => (loading.value = false));
      vm.$store.commit('showMessage', {
        text: 'Success: Created calculation',
        color: 'success',
        timeout: '10000',
      });
    };

    const onSelectedTemplate = () => {
      selectTemplateModal.value = false;
      getCompanyExampleCalculations('api', props.companyConfig.id)
        .then((res) => {
          loadedExampleCalculations.value = res.data;
        })
        .then(() => (loading.value = false));
      vm.$store.commit('showMessage', {
        text: 'Success: Created calculation',
        color: 'success',
        timeout: '10000',
      });
    };

    const onSubmit = () => {
      loading.value = true;
      const isValid = ExampleCalculationsForm.value.validate();
      if (!isValid) return;
    };

    const fetchCalculations = async () => {
      await getCompanyExampleCalculations('api', props.companyConfig.id)
        .then((res) => {
          loadedExampleCalculations.value = res.data;
        })
        .then(() => {
          calculationsToExclude.value = [];
          for (const item in loadedExampleCalculations.value) {
            const dataItem = loadedExampleCalculations.value[item];
            calculationsToExclude.value.push(dataItem.name);
          }
        });
    };

    const fetchOrgTemplates = () => {
      getOrgExampleCalculationTemplates(props.organization.id).then((res) => (loadedTemplates.value = res.data));
    };

    const onDelete = (calculation) => {
      deleteCalculationModal.value = true;
      if (calculation.id) calculationToDelete.value = calculation;
    };

    const onEdit = (item) => {
      calculationToEdit.value = item;
      editCalculationModal.value = true;
    };

    const removeCalculation = (calculationId, isTemplate) => {
      return new Promise((resolve, reject) => {
        try {
          if (!isTemplate) {
            deleteCompanyExampleCalculation(calculationId).then(() => resolve());
          } else {
            removeOrgTemplateForCompany(props.companyConfig.id, calculationId).then(() => resolve());
          }
        } catch (e) {
          reject(e);
        }
      });
    };

    const confirmDelete = async () => {
      if (selectedCalculations.value.length > 0) {
        return confirmMultipleDelete();
      }
      return removeCalculation(calculationToDelete.value.id, !!calculationToDelete.value.inUse).then(() => {
        deleteCalculationModal.value = false;
        fetchCalculations();
      });
    };

    const confirmMultipleDelete = () => {
      const promiseArr = [];

      selectedCalculations.value.map(async (i) => {
        promiseArr.push(removeCalculation(i.id, !!i.inUse));
      });

      Promise.all(promiseArr).then(() => {
        selectedCalculations.value = [];
        deleteCalculationModal.value = false;
        fetchCalculations();
      });
    };
    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchCalculations();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchCalculations();
    };

    onMounted(() => {
      fetchCalculations();
      fetchOrgTemplates();
    });
    return {
      menuref,
      calculationToEdit,
      onEdit,
      save,
      isFormValid,
      onSubmit,
      headers,
      createCalculationModal,
      editCalculationModal,
      setLimit,
      loading,
      handlePagination,
      loadedExampleCalculations,
      loadedTemplates,
      totalCalculations,
      onCreatedCalculation,
      onEditedCalculation,
      selectedCalculations,
      onDelete,
      confirmDelete,
      confirmMultipleDelete,
      deleteCalculationModal,
      calculationsToExclude,
      selectTemplateModal,
      onSelectedTemplate,

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
        mdiClose,
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiDownloadOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
      },
    };
  },
};
</script>
<style lang="scss">
.v-data-table--roles {
  .v-data-footer {
    padding: 0 !important;
  }
}
</style>
