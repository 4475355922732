<template>
  <div>
    <v-form
      :id="formId"
      ref="personalInfoForm"
      v-model="isFormValid"
      class="multi-col-validation"
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-radio-group id="radio-mr" v-model="personalData.salutation" row hide-details class="mt-0">
            <v-radio :label="$t('formLabelSalutationMale')" :value="SALUTATION_MR"></v-radio>
            <v-radio :label="$t('formLabelSalutationFemale')" :value="SALUTATION_MRS"></v-radio>
            <v-radio :label="$t('formLabelSalutationDiverse')" :value="SALUTATION_DIV"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="firstname"
            v-model="personalData.firstName"
            :rules="[validators.blankValidator]"
            :label="$t('formLabelFirstName')"
            :placeholder="$t('formLabelFirstName')"
            dense
            outlined
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="lastname"
            v-model="personalData.lastName"
            :rules="[validators.blankValidator]"
            :label="$t('formLabelLastName')"
            dense
            outlined
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="street-address"
            v-model="personalData.streetAddress"
            dense
            outlined
            :label="$t('formLabelStreetAddress')"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="postal-code"
            v-model="personalData.postalCode"
            dense
            outlined
            :label="$t('formLabelPostalCode')"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field id="city" v-model="personalData.city" dense outlined :label="$t('formLabelCity')">
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-select
            v-model="country"
            :items="countries"
            item-text="text"
            item-value="value"
            return-object
            auto-select-first
            :label="$t('formLabelCountry')"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col md="6" cols="12">
          <v-select
            v-model="selectedCompany"
            :items="companiesOptions"
            :rules="[validators.required]"
            :label="$t('formLabelCompany')"
            dense
            :multiple="multipleCompanySelection"
            outlined
            hide-details
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-select>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="email-business"
            v-model="personalData.emailBusiness"
            :rules="[validators.emailValidator, validators.required]"
            dense
            outlined
            :label="$t('formLabelEmailBusiness')"
            :prepend-inner-icon="icons.mdiEmailOutline"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="personal-number"
            v-model="personalData.personalNumber"
            dense
            outlined
            :label="$t('formLabelPersonalNumber')"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-menu ref="menuref" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                id="dob"
                v-model="taxData.employmentStartDate"
                :label="$t('labelEmployeeStartDate')"
                readonly
                outlined
                dense
                :prepend-inner-icon="icons.mdiCalendar"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              ref="picker"
              v-model="taxData.employmentStartDate"
              min="1950-01-01"
              color="primary"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group id="language" v-model="personalData.language" row hide-details class="mt-3">
            <template #label>
              <div class="text-base label-custom mr-5">
                {{ $t('formLabelLanguagePreference') }}
              </div>
            </template>
            <v-radio class="pt-4" :label="$t('formLabelLanguageGerman')" :value="'de'"></v-radio>
            <v-radio class="pt-4" :label="$t('formLabelLanguageEnglish')" :value="'en'"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group id="radio-mr" v-model="personalData.contract" row hide-details class="mt-3">
            <template #label>
              <div class="text-base label-custom label-existing mr-5">
                {{ $t('formLabelExistingContract') }}
              </div>
            </template>
            <v-radio class="pt-4" :label="$t('yes')" :value="true"></v-radio>
            <v-radio
              class="pt-4"
              :label="$t('no')"
              :value="false"
              :class="{ 'ml-8': $vuetify.breakpoint.smAndDown }"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <slot></slot>
    </v-form>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance, toRefs, computed } from '@vue/composition-api';
import { mdiCalendar, mdiEmailOutline, mdiCellphone } from '@mdi/js';
import { required, emailValidator, phoneNumberValidator, ageValidator, blankValidator } from '@core/utils/validation';
import { createEmployee } from '../../api/employee';
import { createCountrySelection, SALUTATION_MR, SALUTATION_DIV, SALUTATION_MRS } from '@/constants';

export default {
  components: {},
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const date = ref(null);
    const menu = ref(false);
    const picker = ref(null);
    const menuref = ref(null);
    const personalInfoForm = ref(null);
    const taxData = ref({ employmentStartDate: null });
    const isFormValid = ref(false);
    const items = ['Mr', 'Mrs', 'Diverse'];
    const selectedCompany = ref(null);
    const country = ref(null);
    const countries = computed(() => createCountrySelection(currentLang.value === 'en' ? 'en' : 'de'));

    const { personalData, companiesOptions } = toRefs(props);
    if (companiesOptions.value.length === 1) {
      selectedCompany.value = companiesOptions.value[0].value;
    }

    if (props.preselectedCompany && props.multipleCompanySelection) {
      selectedCompany.value = [props.preselectedCompany];
    }

    if (props.personalData.country == '') {
      country.value = { value: 'DE', text: 'Germany' };
    }

    watch(menu, (val) => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'));
    });

    const save = (d) => {
      menuref.value.save(d);
    };

    const removeEmptyEntries = (obj) => {
      return Object.entries(obj)
        .filter(([_, v]) => !!v)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    };

    const addEmployee = () => {
      personalData.value.country = country.value.value;
      const payload = {
        company: props.multipleCompanySelection ? { id: props.preselectedCompany } : { id: selectedCompany.value },
        personalDetails: removeEmptyEntries(personalData.value),
        taxDetails: {
          employmentStartDate: taxData.value.employmentStartDate,
        },
        initialBavContractStatus: personalData.value.contract ? 'CONTRACT_EXISTING' : 'CONTRACT_ONBOARDED',
        languagePreference: {
          language: personalData.value.language,
        },
      };

      createEmployee(payload).then((res) => {
        vm.$emit('created', {
          id: res.data.id,
          companies: selectedCompany.value,
        });
        personalInfoForm.value.reset();
        vm.$nextTick(() => {
          personalData.value.salutation = SALUTATION_MR;
          personalData.value.contract = false;
          personalData.value.language = 'de';
        });
      });
    };

    const onSubmit = () => {
      const isValid = personalInfoForm.value.validate();
      if (!isValid) return;

      addEmployee();
    };

    return {
      date,
      menu,
      picker,
      menuref,
      save,
      items,
      personalInfoForm,
      isFormValid,
      onSubmit,
      countries,
      selectedCompany,
      SALUTATION_MR,
      SALUTATION_MRS,
      SALUTATION_DIV,
      country,
      taxData,

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
        ageValidator,
        blankValidator,
      },
    };
  },
  props: {
    multipleCompanySelection: {
      default: false,
    },
    formId: {
      required: false,
    },
    personalData: {
      type: Object,
      required: false,
    },
    companyData: {
      type: Object,
    },
    preselectedCompany: {
      type: String,
    },
    companiesOptions: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.label-existing {
  min-width: 180px !important;
}
.label-custom {
  position: absolute;
  top: -34px;
  left: 10px;
  width: auto;
  min-width: 75px;
  white-space: nowrap;
  &--2 {
    min-width: 120px;
  }
  .info {
    margin-left: 15px;
  }
}
</style>
