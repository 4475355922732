<template>
  <div>
    <v-form ref="generalSettingsForm" class="multi-col-validation" @submit.prevent="$emit('next')">
      <v-row class="justify-center">
        <v-col cols="12" md="12" class="mx-auto pt-0">
          <v-card flat class="mb-5">
            <v-card-text class="px-0 pt-0">
              <h2>Search & Filter</h2>
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap px-0">
              <v-row class="px-3">
                <v-col cols="12" md="4" class="px-0">
                  <v-text-field
                    v-model="searchTerm"
                    single-line
                    dense
                    outlined
                    clearable
                    hide-details
                    label="Search"
                    placeholder="Search"
                    class="mb-5 me-3"
                    @input="searchData"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="px-0">
                  <!--<v-select-->
                  <!--:items="providerOptions"-->
                  <!--v-model="providerFilter"-->
                  <!--:label="$t('Filter by provider')"-->
                  <!--:placeholder="$t('Filter by provider')"-->
                  <!--clearable-->
                  <!--single-line-->
                  <!--outlined-->
                  <!--dense-->
                  <!--hide-details-->
                  <!--class="mb-5 me-0 pr-0"-->
                  <!--&gt;</v-select>-->
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap pb-0 border-top justify-end">
              <div class="py-5">
                <span>
                  <v-btn color="primary" class="mr-3" @click="createProviderModal = true">
                    {{ $t('Create provider') }}
                  </v-btn>
                </span>
                <span>
                  <v-btn color="primary" @click="createPlanModal = true">
                    {{ $t('Create plan') }}
                  </v-btn>
                </span>
              </div>
            </v-card-text>
            <v-data-table
              v-model="selectedProviders"
              :items="loadedProviders"
              item-key="providerId"
              :headers="headers"
              :server-items-length="totalloadedProviders"
              :loading="loadingProviders"
              :footer-props="{
                'items-per-page-options': [15, 30, 50, 100],
                'items-per-page-text': $t('Providers per page'),
              }"
              show-expand
              :expanded.sync="expanded"
              class="text-no-wrap"
              @update:items-per-page="setLimit"
              @pagination="handlePagination"
            >
              <template #[`item.provider`]="{ item }">
                <span class="text-no-wrap th">{{ item.name }}</span>
              </template>

              <template #[`item.plan`]="{ item }">
                <span class="text-no-wrap th">{{ item.logo }}</span>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-3 py-3 inner-shadow">
                  <span v-if="item.plans.length === 0">No plans</span>
                  <v-simple-table v-else dense light>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Plan</th>
                          <th class="text-left"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(plan, index) in item.plans" :key="index + 'plan'">
                          <td>
                            <v-checkbox
                              v-model="plan.selected"
                              class="mb-2"
                              hide-details
                              dense
                              :label="plan.name"
                              :ripple="false"
                              @change="handlePlanSelection(plan, item)"
                            ></v-checkbox>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>

              <template slot="no-data">
                <div class="my-5">
                  <p>{{ $t('myBavNoResults') }}</p>
                </div>
              </template>
            </v-data-table>
          </v-card>
          <ModalWindow :is-open="createProviderModal" :hasForm="true" title="Create Provider">
            <template #content>
              <v-row class="d-flex justify-center">
                <v-col md="8" cols="12">
                  <v-text-field
                    v-model="createProviderName"
                    label="Name"
                    placeholder="Name"
                    dense
                    clearable
                    outlined
                  ></v-text-field>
                  <v-file-input
                    id="company-logo"
                    v-model="providerLogo"
                    label="Select and upload logo of provider"
                    accept="image/*"
                    hide-details
                  ></v-file-input>
                </v-col>
              </v-row>
            </template>
            <template #actions>
              <v-btn outlined color="primary" @click="createProviderModal = false"> Cancel </v-btn>
              <v-btn :disabled="!(providerLogo && createProviderName)" color="primary" @click="addProvider">
                Add
              </v-btn>
            </template>
          </ModalWindow>
          <ModalWindow :is-open="continueToPlanModal" :hasForm="true" success>
            <template #content>
              <p class="text-base">
                The provider was created successfully. Would you like to continue to the plan part?
              </p>
            </template>
            <template #actions>
              <v-btn outlined color="primary" @click="continueToPlanModal = false"> No </v-btn>
              <v-btn color="primary" @click="(continueToPlanModal = false), (createPlanModal = true)"> Yes </v-btn>
            </template>
          </ModalWindow>
          <ModalWindow :is-open="createPlanModal" :hasForm="true" :maxWidth="680" title="Create Plan">
            <template #content>
              <CreatePlanForm
                :providerData="providerData"
                formId="planInfoForm"
                class="mb-3"
                @close="handleCreatedPlan"
              />
            </template>
            <template #actions>
              <v-btn outlined color="primary" @click="createPlanModal = false"> Cancel </v-btn>
              <v-btn color="primary" type="“submit”" form="planInfoForm"> Add</v-btn>
            </template>
          </ModalWindow>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between mt-5">
        <v-btn outlined @click="$emit('stepBack')"> Back</v-btn>
        <v-btn color="primary" type="submit" @click="$emit('next')"> Continue</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance, toRefs, computed } from '@vue/composition-api';
import { debounce } from 'lodash';
import { mdiCalendar, mdiEmailOutline, mdiCellphone, mdiTrendingUp } from '@mdi/js';
import { required, emailValidator, phoneNumberValidator } from '@core/utils/validation';
import ModalWindow from '@/components/modal/ModalWindow';
import CreatePlanForm from '@/components/create-plan/CreatePlanForm.vue';
import { createProvider, getProviders } from '@/api/providers';
import { getProvidersInUse, useProvider, deleteProvider } from '../../../api/providers';

export default {
  components: { ModalWindow, CreatePlanForm },
  setup() {
    const vm = getCurrentInstance().proxy;

    const loadedProviders = ref([]);
    const totalloadedProviders = ref(0);
    const loadingProviders = ref(false);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');
    const searchTerm = ref('');

    const providerFilter = ref('');

    const selectedProviders = ref([]);
    const plansInUse = ref([]);
    const expanded = ref([]);

    const createProviderModal = ref(false);
    const continueToPlanModal = ref(false);
    const createPlanModal = ref(false);
    const createProviderName = ref(null);
    const providerLogo = ref(null);

    const providersList = ref([]);
    const createdProviderID = ref(null);

    const headers = computed(() => {
      return [
        {
          text: vm.$t('Provider'),
          value: 'provider',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: '',
          value: 'data-table-expand',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const fetchAll = () => {
      loadingProviders.value = true;
      getProvidersInUse(vm.$route.params.id).then((res) => {
        mapCompanyProviders(res);
        getProviders(offset.value, limit.value, searchTerm.value).then((res) => {
          providersList.value = [];
          res.data.page.forEach((provider) => {
            providersList.value.push({ text: provider.name, value: provider.providerId });
            provider.plans.forEach((plan) => {
              plan.selected = !!plansInUse.value.includes(plan.planId);
            });
          });
          loadedProviders.value = res.data.page;

          expanded.value = res.data.page.filter((p) => p.plans.length > 0);
          loadingProviders.value = false;
          totalloadedProviders.value = res.data.total;
        });
      });
    };

    const searchData = debounce(function () {
      fetchAll();
    }, 300);

    const addProvider = () => {
      createProvider(providerLogo.value, createProviderName.value)
        .then((res) => {
          createdProviderID.value = res;
          createProviderModal.value = false;
          continueToPlanModal.value = true;
          providersList.value.unshift({ text: createProviderName.value, value: res });
        })
        .finally(() => {
          providerLogo.value = null;
          createProviderName.value = null;
          loadedProviders.value = [];
          plansInUse.value = [];
          fetchAll();
        });
    };

    const handleCreatedPlan = () => {
      createPlanModal.value = false;
      loadedProviders.value = [];
      plansInUse.value = [];
      fetchAll();
    };

    const mapCompanyProviders = (res) => {
      if (!res.data.list) return [];

      const planIds = res.data.list.map((i) => {
        return i.plans.map((p) => p.planId);
      });
      plansInUse.value = [].concat.apply([], planIds);
    };

    const useProviderForCompany = (provider) => {
      useProvider(vm.$route.params.id, [provider]);
    };

    const removeProviderFromCompany = (provider) => {
      deleteProvider(vm.$route.params.id, provider);
    };

    const handlePlanSelection = (plan, provider) => {
      if (plan.selected) {
        useProviderForCompany({ provider: provider.providerId, plan: plan.planId });
      } else {
        removeProviderFromCompany({ provider: provider.providerId, plan: plan.planId });
      }
    };

    fetchAll();

    return {
      expanded,
      plansInUse,
      headers,
      currentPage,
      limit,
      offset,
      setLimit,
      searchTerm,
      handlePagination,
      loadedProviders,
      selectedProviders,
      searchQuery,
      loadingProviders,
      totalloadedProviders,
      providerFilter,
      createProviderModal,
      continueToPlanModal,
      createPlanModal,
      createProviderName,
      providerLogo,
      addProvider,
      searchData,
      handlePlanSelection,
      handleCreatedPlan,
      providersList,
      createdProviderID,

      providerData: {
        selectedProvider: createdProviderID,
        providerList: providersList,
      },

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
        mdiTrendingUp,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
      },
    };
  },
  props: {
    companyData: {
      type: Object,
    },
  },
};
</script>
<style>
.inner-shadow {
  background-color: rgba(80, 90, 104, 0.1);
  padding: 10px;
}
</style>
